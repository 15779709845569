import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Home from './views/home'
import Teams from './views/teams'
import Organization from './views/organization'
import Schedule from './views/schedule'
import Donations from './views/donations'
import NotFound from './views/not-found'

const App = () => {
  return (
    <Router>
      <Switch>
        {/* <Route component={Home} exact path="/" />
        <Route component={Teams} exact path="/teams" />
        <Route component={Organization} exact path="/organization" />
        <Route component={Schedule} exact path="/schedule" />
        <Route component={Donations} exact path="/donations" />
        <Route
          exact
          path="/shop"
          render={() => {
            return (window.location.href = "https://www.player1apparel.com/collections/team-4cez");
          }}
        /> */}
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
